<template>
  <button
      v-ripple="computedRipple"
      :type="type"
      :class="classes"
      :disabled="disabled"
      @click="onClick"
      class="v-button"
      role="button"
  >
    <span class="v-button__content">
      <slot/>
    </span>
  </button>
</template>

<script>
import { BUTTON_TYPES } from '@/constants/components'
import { Ripple } from '@directives'
import { Colorable, Roundable, Sizeable } from '@/mixins'

export default {
  name: 'VButton',

  directives: {
    Ripple
  },

  mixins: [
    Colorable,
    Sizeable,
    Roundable
  ],

  props: {
    active: Boolean,
    disabled: Boolean,
    icon: Boolean,
    link: Boolean,
    outlined: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: true
    },
    transparent: Boolean,
    type: {
      type: String,
      validator: (type) => BUTTON_TYPES.includes(type),
      default: 'button'
    },
    fullWidth: Boolean,
    inheritBorderRadius: Boolean
  },

  computed: {
    classes () {
      return {
        'v-button_is_active': this.active,
        'v-button_type_full-width': this.fullWidth,
        'v-button_type_icon': this.icon,
        'v-button_type_inherit-border-radius': this.inheritBorderRadius,
        'v-button_type_link': this.link,
        'v-button_type_outlined': this.outlined,
        'v-button_type_transparent': this.transparent,
        ...this.colorClasses,
        ...this.sizeClasses,
        ...this.roundedClasses
      }
    },
    computedRipple () {
      return this.disabled || this.link
        ? false
        : this.ripple
    }
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.v-button {
  @include radius(null);
  position: relative;
  display: inline-flex;
  justify-content: center;
  padding: .75rem 1.5rem;
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 1rem;
  line-height: 100%;
  color: #fff;
  border: none;
  background-color: cl(primary);
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  transition: box-shadow .3s;

  // IE button:active inner-padding bugfix
  &__content {
    display: flex;
    align-items: center;
    z-index: 2;
  }

  &::before,
  &::after {
    z-index: 1;
    opacity: 0;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    transition: opacity .3s;
  }

  &::before {
    background-color: rgba(0, 0, 0, .1);
  }

  &::after {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .3);
  }

  &:hover,
  &.v-button_is_active {
    color: #fff;

    &::before {
      opacity: 1;
    }
  }

  &:active {
    color: #fff;
    @include elevation(6)
  }

  &:focus {
    &::after {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: .5;
    color: #fff;
    pointer-events: none;
    user-select: none;
    cursor: default;
  }

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        background-color: cl($color);

        &:hover {
          background-color: cl($color);
        }

        &:active {
          color: #fff;
        }
      }
    }
  }

  &_size {
    &_xs {
      padding: .3rem .7rem;
      font-size: .5rem;

      &.v-button_type_icon {
        font-size: 1rem;
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    &_sm {
      padding: .5rem 1rem;
      font-size: .75rem;
    }

    &_lg {
      padding: .9rem 1.6rem;
      font-size: 1.25rem;

      &.v-button_type_icon {
        width: 3rem;
        height: 3rem;
      }
    }

    &_xl {
      padding: 1.2rem 1.7rem;
      font-size: 1.5rem;

      &.v-button_type_icon {
        font-size: 1rem;
        width: 4rem;
        height: 4rem;
      }
    }
  }

  &_type {
    &_full-width {
      width: 100%;
      justify-content: center;
    }
    &_inherit-border-radius {
      border-radius: inherit;
    }
    &_link {
      padding: 0;
      background-color: transparent;
      color: cl(primary);
      white-space: break-spaces;
      text-align: left;

      &:hover,
      &:active,
      &:focus,
      &:disabled {
        background-color: transparent;
        box-shadow: none;
        color: cl(primary);

        &::before,
        &::after {
          opacity: 0;
        }
      }
    }

    &_icon {
      justify-content: center;
      align-items: center;
      width: 2.37rem;
      height: 2.37rem;
      padding: 0;

      &.v-button_size {
        &_sm {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    &_outlined {
      background-color: transparent;
      color: cl(primary);
      box-shadow: inset 0 0 0 1px;
      transition: background-color .3s ease, color .3s ease;

      &:hover,
      &.v-button_is_active {
        background-color: cl(primary);
        color: #FFFFFF;
      }

      &:active {
        color: #FFFFFF;
        background-color: cl(primary);
      }

      &::before {
        display: none;
      }

      &.v-button_color {
        @each $color in map-keys($theme-colors) {
          &_#{$color} {
            color: cl($color);

            &:hover {
              color: #FFFFFF;
              background-color: cl($color);
            }

            &:active,
            .v-button_is_active {
              color: #FFFFFF;
              background-color: cl($color);
            }
          }
        }
      }
    }

    &_transparent {
      color: cl(primary);
      background-color: transparent;

      &::before {
        background-color: rgba(cl(rgb-primary), .1);
      }

      &:active {
        box-shadow: none;
      }

      &:hover,
      &:active {
        color: cl(primary);
        background-color: transparent;

        &::before {
          opacity: .6;
          background-color: rgba(cl(rgb-primary), .1);
        }
      }

      &.v-button_color {
        @each $color in map-keys($colors) {
          &_#{$color} {
            &::before {
              background-color: rgba(cl(rgb-#{$color}), .1);
            }

            &:hover,
            &:active {
              color: cl($color);
              background-color: transparent;

              &::before {
                opacity: .6;
                background-color: rgba(cl(rgb-#{$color}), .1);
              }
            }
          }
        }
      }
    }
  }
}
</style>

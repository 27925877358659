<template>
  <div
    :class="classes"
    class="v-table"
  >
    <table class="v-table__table">
      <thead
        v-if="this.$slots.thead"
        class="v-table-thead"
      >
      <slot name="thead"></slot>
      </thead>

      <tbody class="v-table-tbody">
      <slot name="default"></slot>
      </tbody>

      <tfoot
        v-if="this.$slots.tfoot"
        class="v-table-tfoot"
      >
      <slot name="tfoot"></slot>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { Roundable } from '@mixins'

export default {
  name: 'VTable',

  mixins: [
    Roundable
  ],

  props: {
    rounded: {
      type: [Boolean, String],
      default: true
    },
    sticky: {
      type: Boolean,
      default: true
    },
    list: Boolean,
    striped: Boolean,
    narrow: Boolean,
    withSpan: Boolean,
    unbordered: Boolean
  },

  computed: {
    classes () {
      return {
        'v-table_type_sticky': this.sticky,
        'v-table_type_striped': this.striped,
        'v-table_type_narrow': this.narrow,
        'v-table_type_list': this.list,
        'v-table_type_unbordered': this.unbordered,
        'v-table_with_span': this.withSpan,
        ...this.roundedClasses
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

.v-table {
  padding: 0;
  margin: 0;
  border: 1px solid cl(border);

  &__table {
    position: relative;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
    text-align: left;
  }

  &_type {
    &_narrow {
      overflow: visible;

      .v-table-th,
      .v-table-td {
        padding: 2px;
        font-size: 0.7rem;
        word-wrap: break-word;
      }
    }

    &_sticky {
      .v-table-thead {
        z-index: 2;
        position: sticky;
        top: 0;
        background-color: inherit;
      }
    }

    &_striped {
      .v-table-tbody {
        .v-table-tr:nth-child(even) {
          background-color: var(--v-table-striped-color);

          &.v-table-tr_is_active {
            background-color: var(--v-table-active-color);
          }
        }
      }
    }

    &_list {
      .v-table-th,
      .v-table-td {
        &:not(:last-of-type) {
          border-right: none;
        }
      }
    }

    &_unbordered {
      border: none;
    }
  }

  &_with {
    &_span {
      border: none;

      .v-table__table {
        border-collapse: collapse;
        border-spacing: 0;
        border-radius: inherit;
      }

      .v-table-tr {
        border: none
      }

      .v-table-th {
        border: 1px solid cl(border);
      }

      .v-table-td {
        border: 1px solid cl(border);
      }
    }
  }

}
</style>

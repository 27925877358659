<template>
  <div class="table-radio">
    <div class="table-radio__content">
      <v-table
        v-resize="debounce(onTableResize)"
        :striped="!unique"
        :narrow="narrow"
      >
        <template #thead>
          <v-table-tr>
            <v-table-th
              :vertical="narrow"
              class="v-table-th_is_first"
            >
              <slot name="th-first"></slot>
            </v-table-th>

            <v-table-th
              ref="thAnswers"
              v-for="tableTitle in variantCol"
              :key="tableTitle.code"
              :style="thStyles"
              :vertical="narrow"
            >
              <span v-html="tableTitle.text"/>
            </v-table-th>
          </v-table-tr>
        </template>

        <template #default>
          <v-table-tr
            v-for="(row, i) in variantMap"
            :key="i"
            :active="row !== null"
            :error="row === null && showWarnings"
          >
            <v-table-td>
              <span v-html="variantRow[i].text"/>
            </v-table-td>

            <v-table-td
              v-for="(col, j) in variantCol"
              v-ripple="ripple"
              :key="j"
              :active="unique && variantMap.includes(j)"
              class="p-0"
            >
              <v-radio
                :value="j"
                :model-value="variantMap[i]"
                full-height
                @change="onRadioChange($event, i)"
              ></v-radio>
            </v-table-td>
          </v-table-tr>
        </template>
      </v-table>
    </div>

    <div class="table-radio__content table-radio__content_is_mobile">
      <div
        v-for="(row, i) in variantMap"
        :key="`row-${i}`"
        class="mb-24"
      >
        <p class="text-bold" v-html="variantRow[i].text"/>

        <card-button-radio
          v-for="(col, j) in variantCol"
          :key="`col-${j}`"
          :value="j"
          :model-value="variantMap[i]"
          :error="row === null && showWarnings"
          @change="onRadioChange($event, i)"
        >
          <span v-html="col.text"/>
        </card-button-radio>
      </div>
    </div>
  </div>
</template>

<script>
import Bowser from 'bowser'
import { convertToColorSpectrum, debounce } from '@utils/helpers'
import { Resize, Ripple } from '@directives'
import { VRadio, VTable, VTableTd, VTableTh, VTableTr } from '@components/base'
import CardButtonRadio from '@components/features/CardButtonRadio/CardButtonRadio'

export default {
  name: 'TableRadio',

  directives: {
    Resize,
    Ripple
  },

  components: {
    CardButtonRadio,
    VRadio,
    VTable,
    VTableTd,
    VTableTh,
    VTableTr
  },

  props: {
    completion: Boolean,
    narrow: Boolean,
    ripple: {
      type: [Boolean, Object],
      default: () => ({ class: 'text-color-primary' })
    },
    showWarnings: Boolean,
    unique: Boolean,
    variantCol: Array,
    variantRow: Array,
    values: {
      type: Array,
      defaults: []
    }
  },

  data () {
    return {
      bowser: Bowser.getParser(window.navigator.userAgent),
      // в каком виде отдавать данные
      returnedDataPattern: (rowId, colId) => `${rowId};${colId}`,
      thAnswersWidth: 0,
      cachedVariantMap: [] // сохранение временного состояния для вычесления результата
    }
  },

  computed: {
    cachedVariantMapAnswerLength () {
      return this.cachedVariantMap.filter(variant => variant !== null && variant !== -1).length
    },
    thStyles () {
      return {
        minWidth: this.thAnswersWidth === 0 ? 'auto' : `${this.thAnswersWidth}px`
      }
    },
    variantMap () {
      const valuesMap = this.values?.reduce((result, current) => {
        const [rowCode, colCode] = current.split(';')
        const answerColIndex = this.variantCol.findIndex(col => col.code === colCode)

        return {
          ...result,
          [rowCode]: answerColIndex
        }
      }, {}) || {}

      return this.variantRow.map((value, index) => {
        if (valuesMap[value.code] !== undefined) {
          return valuesMap[value.code]
        }

        return null
      }, [])
    }
  },

  mounted () {
    this.onTableResize()
  },

  methods: {
    debounce,

    /**
     * Отдаёт текущий цвет из диапазона
     * @param  {number} currIndex - индекс текущей ячейки
     * @returns {string|null} - цвет для ячейки
     */
    getThColor (currIndex) {
      const lastIndex = this.variantCol.length - 1

      return convertToColorSpectrum({
        rgb: '223, 230, 235',
        currIndex,
        minOpacity: 0.3,
        maxIndex: lastIndex
      })
    },

    /**
     * Обработчик смены радиокнопок
     * @param {string | number} value - значение кнопки
     * @param {number} valueIndex - ряд, в котором находится кнопка
     */
    onRadioChange (value, valueIndex) {
      this.cachedVariantMap = [...this.variantMap]

      if (this.unique) {
        const oldIndex = this.cachedVariantMap.indexOf(value)
        const currVal = this.completion
          ? this.cachedVariantMap[valueIndex]
          : null

        // замена старого значения на уже имеющееся
        if (oldIndex > -1) {
          this.cachedVariantMap.splice(oldIndex, 1, currVal)
        }

        // убрать подкрашивание дозаполненых ниже колонок
        this.cachedVariantMap = this.cachedVariantMap.map(variant => {
          return variant === -1 ? null : variant
        })
      }

      this.cachedVariantMap.splice(valueIndex, 1, value)

      // последовательность важна!
      // подкрашивание оставшихся колонок при выборе нужного количества вариантов
      if (this.unique) {
        if (this.completion) {
          // дозаполнение колонок если вариант остался только один
          const condition = this.cachedVariantMapAnswerLength === this.variantCol.length - 1 &&
            this.variantCol.length === this.variantRow.length

          if (condition) {
            const nullRowIndex = this.cachedVariantMap.indexOf(null)
            const notUsedValue = this.variantCol
                                     .map((val, i) => i)
                                     .find(value => this.cachedVariantMap.indexOf(value) === -1)

            this.cachedVariantMap.splice(nullRowIndex, 1, +notUsedValue)
          }

          // последовательность важна!
          // подкрашивание всех колонок при выборе всех возможных вариантов
          if (this.cachedVariantMapAnswerLength === this.variantCol.length) {
            this.cachedVariantMap = this.cachedVariantMap.map(variant => {
              return variant === null ? -1 : variant
            })
          }
        } else {
          if (
            this.cachedVariantMapAnswerLength === this.variantCol.length ||
            this.cachedVariantMapAnswerLength === this.variantRow.length
          ) {
            this.cachedVariantMap = this.cachedVariantMap.map(variant => {
              return variant === null ? -1 : variant
            })
          }
        }
      }

      const answer = this.cachedVariantMap.reduce((result, col, i) => {
        if (this.variantCol[col]) {
          return [
            ...result,
            this.returnedDataPattern(this.variantRow[i].code, this.variantCol[col].code)
          ]
        }

        return result
      }, [])

      this.$emit('change', answer)
    },

    /**
     * Действие при изменении размеров таблицы
     */
    onTableResize () {
      this.setThWidth()
    },

    /**
     * Уровнять ширину th в таблице для safari,
     * все остальные выравнивают сами
     * Неожиданная работа writing-mode: vertical-rl в safari
     */
    setThWidth () {
      this.thAnswersWidth = 0

      this.$nextTick(() => {
        this.$refs.thAnswers.forEach(el => {
          let cellWidth = el.$el.clientWidth

          if (this.narrow) {
            cellWidth = [].find.call(el.$el.childNodes, elem => {
              return elem.className.indexOf('v-table-th__text') > -1
            }).clientWidth + 4
          }

          if (cellWidth > this.thAnswersWidth) {
            this.thAnswersWidth = cellWidth
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:map";
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

.table-radio {
  &__content {
    @include media-breakpoint-down(md) {
      display: none;
    }

    &_is {
      &_mobile {
        display: none;

        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
  }
}
</style>

import { store } from '@/store'

let _timer = null

export default class ShowMessageOnTimer {
  constructor (
    message = 'Ваше Интернет-соединение нестабильно. Пожалуйста, подождите.',
    duration = 10000
  ) {
    this.duration = duration
    this.message = message
  }

  start (message, duration) {
    _clearTimer()
    _setTimer.bind(this)()

    if (message) {
      this.setMessage(message)
    }

    if (duration) {
      this.setDuration(duration)
    }
  }

  stop () {
    _clearTimer()
    _hideWarningMessage()
  }

  setMessage (message) {
    this.message = message
  }

  setDuration (duration) {
    this.duration = duration
  }
}

function _setTimer () {
  _timer = window.setTimeout(_showWarningMessage.bind(this), this.duration)
}

function _clearTimer () {
  window.clearTimeout(_timer)
  _timer = null
}

function _showWarningMessage () {
  store.dispatch('snackbar/showSnackbar', {
    content: this.message,
    options: { endless: true }
  })
}

function _hideWarningMessage () {
  store.dispatch('snackbar/hideSnackbar')
}
